import React, { useRef } from 'react';

import { Header } from '../header/Header';
import { DayTimeTable } from '../DayTimeTable';
import { useFlexworxConfig } from 'utils/flexworx-config';
import { AppointmentArea } from './AppointmentArea';
import { Appointment } from 'app/api/AppointmentApi';
import { useSchedulePreFetcher } from '../SchedulePreFetcher';
import { LaunchpadModalContainerProps } from './LaunchpadModalContainer';
import { useAppointmentFormContext } from '../AppointmentFormHook';

export type ScheduleDisplayForPatientAppointmentProps = LaunchpadModalContainerProps & {
  onAppointmentSubmitSuccess: (appointment: Appointment) => void;
};

export function ScheduleDisplayForPatientAppointment({
  closeModal,
  hasAppointmentsProvider,
  onAppointmentSubmitSuccess,
}: ScheduleDisplayForPatientAppointmentProps) {
  const {
    scheduleDefinitionIdWatch: scheduleDefinitionId,
    dateWatch: date,
    dateSetValue: setDate,
    timeWatch: time,
    scheduleDefinition,
  } = useAppointmentFormContext();

  const { isLoading } = useFlexworxConfig();

  const monthCalendarHideButton = useRef<HTMLButtonElement>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  const tableHolderStyle = { backgroundColor: '#ffffff' };

  const { onRefresh: _onRefresh, isRefreshing } = useSchedulePreFetcher();
  const onRefresh = () => _onRefresh(scheduleDefinitionId!, date!);

  return (
    <>
      <div className="gap-6">
        <div className="rounded-lg bg-white shadow px-1 py-1" style={tableHolderStyle}>
          <div className="md:flex md:flex-row-reverse" style={tableHolderStyle}>
            <div className="md:flex-grow  items-center justify-between space-x-6 p-2">
              <div className="flex-1 max-h-[calc(100vh-40vh)] overflow-y-auto">
                <div className="flex flex-col mb-2 items-center space-x-3 w-full p-2">
                  <AppointmentArea closeModal={closeModal} onAppointmentSubmitSuccess={onAppointmentSubmitSuccess} />
                </div>
              </div>
            </div>
            <div className="md:w-4/12 flex flex-col flex-none items-center">
              <>
                <div className=" w-full mt-1">
                  {date && (
                    <Header
                      date={date}
                      setDate={setDate}
                      scheduleDefinition={null}
                      monthCalendarToggleButton={monthCalendarHideButton}
                      hideToday={false}
                      styleGuide={tableHolderStyle}
                      onRefresh={onRefresh}
                      isRefreshing={isRefreshing}
                    />
                  )}
                </div>
                <div className="w-full justify-between max-h-[calc(100vh-50vh)] overflow-y-auto">
                  <div className="flex-1">
                    <div className="flex mb-2 w-full items-center space-x-3" ref={containerRef}>
                      {date && (
                        <>
                          {scheduleDefinition && (
                            <DayTimeTable
                              scheduleDefinition={scheduleDefinition}
                              date={date}
                              isLoading={isLoading}
                              reduceColumns={true}
                              allowRightClick={false}
                              time={time}
                              containerRef={containerRef}
                              hasAppointmentsProvider={hasAppointmentsProvider}
                              headerClearance={0}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
