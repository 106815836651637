import useApiClient from 'utils/apiClientHook';
import { Auditable } from './Auditable';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Appointment } from './AppointmentApi';

export type NewTemporaryNoteRequest = {
  header?: string;
  body: string;
  temporaryNoteType: 'ScheduleDefinition';
  // yyyy-MM-dd
  noteDate: string;
  // HH:mm
  startTime: string;
  // HH:mm
  endTime: string;
  isAllDay: boolean;
  blockAppointments: boolean;
  targetId: string;
  color: string;
};

export type TemporaryNote = NewTemporaryNoteRequest & Auditable;

export const useGetTemporaryNote = (id: string) => {
  const { computeClient: client } = useApiClient();
  return useQuery(['temporary-note', id], () =>
    client.get<TemporaryNote>(`/temporary-note/${id}`).then(response => response.data),
  );
};

export const useGetTemporaryNotesBySchedule = (
  enabled: boolean,
  scheduleDefinitionId: string | null | undefined,
  date: string | null | undefined,
) => {
  const { computeClient: client } = useApiClient();
  return useQuery(['temporary-note', 'schedule', scheduleDefinitionId, date], () => {
    if (!scheduleDefinitionId || !date) return Promise.resolve([]);

    return client
      .get<TemporaryNote[]>(`/temporary-note/schedule/${scheduleDefinitionId}/${date}`)
      .then(response => response.data);
  });
};

export const useCreateTemporaryNote = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<TemporaryNote, AxiosError, NewTemporaryNoteRequest>({
    mutationFn: async function (request: NewTemporaryNoteRequest) {
      const response = await client.post('/temporary-note', request);
      return response.data;
    },
    onSuccess: () => {
      queryClient.refetchQueries(['temporary-note', 'schedule']);
    },
  });
};

export const useUpdateTemporaryNote = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<TemporaryNote, AxiosError, TemporaryNote>({
    mutationFn: request => client.put('/temporary-note', request).then(response => response.data),
    onSuccess: () => {
      queryClient.refetchQueries(['temporary-note', 'schedule']);
    },
  });
};

type temporaryNoteDeletionResponse = {
  id: string;
  scheduleDefinitionId: string;
  date: string;
};

export const useDeleteTemporaryNote = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<temporaryNoteDeletionResponse, AxiosError, string>({
    mutationFn: id => client.delete(`/temporary-note/${id}`).then(response => response.data),
    onSuccess: response => {
      queryClient.removeQueries(['temporary-note', response.id]);
      queryClient.refetchQueries(['temporary-note', 'schedule', response.scheduleDefinitionId, response.date]);
      queryClient.refetchQueries(['temporary-note', response.scheduleDefinitionId, response.date]);
    },
  });
};

export const useInvalidateTemporaryNote = () => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries(['temporary-note']);
  };
};

export const useAddTemporaryNoteInCache = () => {
  const queryClient = useQueryClient();
  return (note: TemporaryNote) => {
    // unintended benefit
    const existingCachedAppointment = queryClient.getQueryData<Appointment>(['temporary-note', note.id]);
    if (!existingCachedAppointment) {
      queryClient.setQueryData(['temporary-note', note.id], note);
    }

    // intended benefit
    queryClient.setQueryData<TemporaryNote[]>(['temporary-note', 'schedule', note.targetId, note.noteDate], old => {
      console.log('yo', note);
      if (!old) {
        console.log('old', old);
        return old;
      }
      if (old.find(a => a.id === note.id)) {
        console.log('find', note);
        return old;
      }
      console.log('yo2', note);
      return [...old, note];
    });
  };
};

export const useUpdateTemporaryNoteInCache = () => {
  const queryClient = useQueryClient();
  return (note: TemporaryNote) => {
    queryClient.setQueryData<TemporaryNote>(['temporary-note', note.id], old => {
      if (!old) {
        return old;
      }
      if (!old.updatedAt || new Date(old.updatedAt!) < new Date(note.updatedAt!)) {
        return note;
      }
      return old;
    });
    queryClient.setQueryData<TemporaryNote[]>(['temporary-note', 'schedule', note.targetId, note.noteDate], old => {
      if (!old) {
        return old;
      }
      const index = old.findIndex(a => a.id === note.id);
      if (index === -1) {
        return old;
      }
      if (!old[index].updatedAt || new Date(old[index].updatedAt!) < new Date(note.updatedAt!)) {
        const newNotes = [...old];
        newNotes[index] = note;
        return newNotes;
      }
      return old;
    });
  };
};

export const useDeleteTemporaryNoteInCache = () => {
  const queryClient = useQueryClient();
  return (note: { id: string }) => {
    let scheduleDefinitionId: string | null = null;
    let noteDate: string | null = null;

    const queries = queryClient.getQueryCache().getAll();
    const noteQueries = queries.filter(query => {
      const queryKey = query.queryKey;
      return queryKey[0] === 'temporary-note';
    });
    const allNotes = noteQueries.map(query => query.state.data as TemporaryNote).flat();

    if (allNotes) {
      try {
        const index = allNotes.findIndex(a => a.id === note.id);
        if (index !== -1) {
          scheduleDefinitionId = allNotes[index].targetId;
          noteDate = allNotes[index].noteDate;
        }
      } catch (e) {
        console.error(e);
      }
    }

    queryClient.setQueryData<TemporaryNote>(['temporary-note', note.id], undefined);

    if (scheduleDefinitionId && noteDate) {
      queryClient.setQueryData<TemporaryNote[]>(['temporary-note', 'schedule', scheduleDefinitionId, noteDate], old => {
        if (!old) {
          return old;
        }
        const index = old.findIndex(a => a.id === note.id);
        if (index === -1) {
          return old;
        }
        const newNotes = [...old];
        newNotes.splice(index, 1);
        return [...newNotes];
      });
    }
  };
};
