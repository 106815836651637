import { Appointment, useBustAppointmentCache, useGetAppointment } from 'app/api/AppointmentApi';
import { Modal } from '../../Modal';
import React, { useState } from 'react';
import { Patient, useGetPatient } from 'app/api/PatientApi';
import { LaunchpadModalContainer, LaunchpadModalContainerProps } from './LaunchpadModalContainer';
import { AppointmentsProvider } from '../AppointmentsHook';
import { TemporaryNotesProvider } from '../TemporaryNotesHook';
import { ActiveBucketProvider } from '../../../pages/SchedulePage/ActiveBucketProvider';
import { Bucket } from '../BucketOperations';
import { AppointmentFormProvider, useAppointmentFormContext } from '../AppointmentFormHook';
import { LoadingWrapper } from '../../../../utils/loadingWrapper';

export type AppointmentModalEditProps = {
  isOpen: boolean;
  closeModal: () => void;
  appointmentId: string;
  hasAppointmentsProvider: boolean;
  onAppointmentSubmitSuccess: (appointment: Appointment) => void;
};

export const AppointmentModalEdit = (props: AppointmentModalEditProps) => {
  return <div>{props.isOpen && <AppointmentModalEditHelper {...props} />}</div>;
};

export const AppointmentModalEditHelper = ({
  isOpen,
  closeModal: _closeModal,
  appointmentId,
  hasAppointmentsProvider,
  onAppointmentSubmitSuccess,
}: AppointmentModalEditProps) => {
  const bustCache = useBustAppointmentCache(appointmentId);

  const closeModal = () => {
    _closeModal();
    bustCache();
  };

  const { data: appointment, isLoading, isError } = useGetAppointment(appointmentId);

  return (
    <>
      {isOpen && (
        <Modal width="sm:max-w-7xl" closeModalText={'Cancel'} open={isOpen} closeModal={closeModal}>
          <LoadingWrapper isLoading={isLoading} isError={isError}>
            {appointment && (
              <AppointmentModalRender
                isOpen={isOpen}
                closeModal={closeModal}
                appointment={appointment}
                hasAppointmentsProvider={hasAppointmentsProvider}
                onAppointmentSubmitSuccess={onAppointmentSubmitSuccess}
              />
            )}
          </LoadingWrapper>
        </Modal>
      )}
    </>
  );
};

export type AppointmentModalRenderProps = {
  isOpen: boolean;
  closeModal: () => void;
  appointment: Appointment;
  hasAppointmentsProvider: boolean;
  onAppointmentSubmitSuccess: (appointment: Appointment) => void;
};

const AppointmentModalRender = ({ isOpen, closeModal, appointment, onAppointmentSubmitSuccess }: AppointmentModalRenderProps) => {
  const { data: patient, isLoading: patientIsLoading, isError: patientIsError } = useGetPatient(appointment.patientId);

  return (
    <LoadingWrapper isLoading={patientIsLoading} isError={patientIsError}>
      <AppointmentModalBody
        patient={patient!}
        appointment={appointment!}
        closeModal={closeModal}
        onAppointmentSubmitSuccess={onAppointmentSubmitSuccess}
      />
    </LoadingWrapper>
  );
};

type AppointmentModalBodyProps = {
  patient: Patient;
  appointment: Appointment;
  closeModal: () => void;
  onAppointmentSubmitSuccess: (appointment: Appointment) => void;
};

// this component is used when we have a patient object & and appointment object.
const AppointmentModalBody = ({ patient, appointment, closeModal, onAppointmentSubmitSuccess }: AppointmentModalBodyProps) => {
  const defaultValues: Appointment = {
    id: appointment.id,
    createdAt: appointment.createdAt,
    organizationId: appointment.organizationId,
    patientFirstName: patient.firstName,
    patientLastName: patient.lastName,
    patientBirthDate: patient.birthDate,
    patientPhone1: patient.phone1,
    patientPhone2: patient.phone2,
    updatedAt: appointment.updatedAt,
    createdBy: appointment.createdBy,
    updatedBy: appointment.updatedBy,
    appointmentNote: appointment.appointmentNote,
    additionalInfo: appointment.additionalInfo,
    patientId: appointment.patientId,
    appointmentDate: appointment.appointmentDate,
    appointmentTime: appointment.appointmentTime,
    scheduleDefinitionId: appointment.scheduleDefinitionId,
    appointmentTypeId: appointment.appointmentTypeId,
    appointmentStatusId: appointment.appointmentStatusId,
    durationMinutes: appointment.durationMinutes,
    patientDisplayLongReverse: appointment.patientDisplayLongReverse,
    patientDisplayLong: appointment.patientDisplayLong,
    patientDisplayShortReverse: appointment.patientDisplayShortReverse,
    patientDisplayShort: appointment.patientDisplayShort,
  };

  return (
    <AppointmentFormProvider
      defaultValues={defaultValues}
      initialPatient={patient}
      initialScheduleDefinitionId={appointment.scheduleDefinitionId}
      initialTime={appointment.appointmentTime}
      initialDate={appointment.appointmentDate}
    >
      <AppointmentFormProviderInsulation
        closeModal={closeModal}
        shouldMoveTabsUp={true}
        onAppointmentSubmitSuccess={onAppointmentSubmitSuccess}
      />
    </AppointmentFormProvider>
  );
};

export const AppointmentFormProviderInsulation = ({
  closeModal,
  shouldMoveTabsUp,
  onAppointmentSubmitSuccess,
}: {
  closeModal: () => void;
  shouldMoveTabsUp: boolean;
  onAppointmentSubmitSuccess: (appointment: Appointment) => void;
}) => {
  const {
    scheduleDefinitionIdWatch: scheduleDefinitionId,
    dateWatch: date,
    timeWatch: time,
    timeSetValue: setTime,
  } = useAppointmentFormContext();
  const [activeBucketKey, setActiveBucketKey] = useState<string | null>(null);

  const onSetActive = (bucket: Bucket) => {
    setActiveBucketKey(bucket.key);
    setTime(bucket.time());
  };

  const props: LaunchpadModalContainerProps = {
    activeBucketKey: activeBucketKey,
    closeModal: closeModal,
    hasAppointmentsProvider: true,
    onAppointmentSubmitSuccess,
    shouldMoveTabsUp,
  };

  return (
    <AppointmentsProvider scheduleDefinitionId={scheduleDefinitionId} date={date}>
      <TemporaryNotesProvider scheduleDefinitionId={scheduleDefinitionId} date={date}>
        <>
          {date && (
            <ActiveBucketProvider
              time={time}
              onSetActive={onSetActive}
              appointmentId={null}
              setAppointment={() => {}}
              setTime={setTime}
              date={date!}
              scheduleDefinitionId={scheduleDefinitionId}
            >
              <LaunchpadModalContainer {...props} />
            </ActiveBucketProvider>
          )}
        </>
      </TemporaryNotesProvider>
    </AppointmentsProvider>
  );
};
